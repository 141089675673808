.formGroup {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}
@media screen and (min-width: 600px) {
  .formGroup {
    align-items: center;
    flex-direction: row;
    margin-top: 0.2rem;
    margin-bottom: 1rem;
  }

  .formGroup label {
    margin-bottom: 20px;
  }
}

.formGroup label {
  color: #4d4d4d;
  font-size: 18px;
  flex-grow: 1;
  margin-bottom: 15px;
  font-weight: 300;
}

.input {
  display: flex;
  min-height: 60px;
  position: relative;
  flex-flow: column wrap;
}

input,
select {
  height: 40px;
  box-sizing: border-box;
  padding-left: 8px;
  border-radius: 8px;
  border: 1px solid #a6a6a6;
  color: #143b6b;
  font-size: 16px;
  background-color: #fff;
}
@media screen and (min-width: 600px) {
  input,
  select {
    max-width: initial;
    min-width: 285px;
    padding-left: 10px;
  }
}

.inputError {
  border-color: var(--color-error);
}

.select {
  background-color: #fff;
}
