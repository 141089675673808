.emailCheck {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emailCheckIcon {
  width: 24px;
}
