input[type='checkbox'] {
  height: 20px;
  min-width: 20px;
}

.marketingOption {
  width: 100%;
  margin: 2rem 0;
  display: flex;
  font-weight: 300;
}
@media screen and (min-width: 600px) {
  .marketingOption {
    margin: 0;
  }
}

.marketingOption p {
  color: #4d4d4d;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 1rem;
  max-width: 15rem;
}
@media screen and (min-width: 600px) {
  .marketingOption p {
    margin: 15px 0 5px;
  }
}

.marketingOption label {
  font-weight: 300;
}

.marketingOptionDE {
  flex-grow: 1;
  margin-bottom: 2rem;
}
@media screen and (min-width: 600px) {
  .marketingOptionDE {
    margin: 0;
  }
}

.marketingOptionDE p {
  color: #4d4d4d;
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 1rem;
  max-width: 17rem;
}
@media screen and (min-width: 600px) {
  .marketingOptionDE p {
    margin: 15px 0 5px;
  }
}

.marketingOptionDE label {
  color: #4d4d4d;
  font-size: 12px;
  line-height: 1.5;
  margin-left: 0.5rem;
  display: inline;
  font-weight: normal;
}
