.inputFeedback {
  max-width: 285px;
  display: flex;
  margin-top: 0.1rem;
}

.inputFeedback svg {
  width: 16px;
  margin-right: 0.2rem;
  margin-top: 0.1rem;
}

.inputFeedback p {
  color: var(--color-error);
  font-size: 12px;
  line-height: 1.5;
  margin: 0;
}

.inputFeedback a {
  text-decoration: underline;
  color: #246ac1;
}

.inputFeedback a:hover {
  text-decoration: underline !important;
}
