/* stylelint-disable font-family-name-quotes */

/* Orion Design Token Imports */
@import '@iwoca/orion/css/colors.css';
@import '@iwoca/orion/lib/css/orion.css';
@import '@iwoca/orion/css/radii.css';
@import '@iwoca/orion/css/spacers.css';
@import '@iwoca/orion/css/shadows.css';
@import '@iwoca/orion/css/text.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
