@media screen and (min-width: 600px) {
  .formGroupContainer {
    min-height: 150px;
  }
}

.bottom {
  display: flex;
  margin-top: 0;
  flex-direction: column;
}

@media screen and (min-width: 600px) {
  .bottom {
    flex-direction: row;
    margin-top: 1rem;
    align-items: center;
  }
}

.bottom label {
  color: #4d4d4d;
  font-size: 16px;
  line-height: 1.5;
  margin-left: 0.5rem;
  margin-top: -5px;
  max-width: 300px;
}

.mobileBottom {
  display: block;
  margin: auto;
}
@media screen and (min-width: 600px) {
  .mobileBottom {
    display: none;
  }
}

.mobileBottom .callUs {
  color: #000;
  margin: 3rem 0;
}

.mobileBottom .callUs p {
  color: #000;
  margin: 0;
  text-align: center;
}

.mobileBottom .callUs a {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobileBottom .callUs a svg {
  margin-right: 5px;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.submitButton {
  min-width: 250px;
  width: 100%;
  max-width: 300px;
}
@media screen and (min-width: 600px) {
  .submitButton {
    min-width: 283px;
  }
}

.submitButton .submitting {
  opacity: 0.5;
}
