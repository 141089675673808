.noAmountCheckBox {
  display: flex;
  margin-top: var(--spacer-s);
  margin-bottom: var(--spacer-m);

  & label {
    font: var(--text-m) !important;
    font-weight: 300 !important;
    margin-bottom: 0 !important;
  }
}
